/* You can add global styles to this file, and also import other style files */
$font-family-base: "Nunito Sans";

:root {
  --main-bg-color: #0081cc;
}

html,
body {
  height: 100vh;
  margin: 0;
  font-size: 16px;
  font-family: $font-family-base;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.no-result-found {
  height: 40rem;
  span {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 1439px) {
  html,
  body {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1023px) {
  html,
  body {
    font-size: 10px;
  }
}
